import { template as template_0ff2ff07ff024e029333bba8ca562a35 } from "@ember/template-compiler";
const FKText = template_0ff2ff07ff024e029333bba8ca562a35(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
