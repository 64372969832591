import { template as template_627fe60a7743417b86572bee136f61fc } from "@ember/template-compiler";
const FKLabel = template_627fe60a7743417b86572bee136f61fc(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
