import { template as template_39759226b06b4b9c8011a2f0d77d85ef } from "@ember/template-compiler";
import concatClass from "discourse/helpers/concat-class";
const FKSection = template_39759226b06b4b9c8011a2f0d77d85ef(`
  <div class={{concatClass "form-kit__section" @class}} ...attributes>
    {{#if @title}}
      <h2 class="form-kit__section-title">{{@title}}</h2>
    {{/if}}

    {{#if @subtitle}}
      <span class="form-kit__section-subtitle">{{@subtitle}}</span>
    {{/if}}

    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKSection;
