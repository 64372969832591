import { template as template_ebe0bf0ddca24c31bb548034e8516611 } from "@ember/template-compiler";
import { on } from "@ember/modifier";
const BulkSelectCell = template_ebe0bf0ddca24c31bb548034e8516611(`
  <td class="bulk-select topic-list-data">
    <label for="bulk-select-{{@topic.id}}">
      <input
        {{on "click" @onBulkSelectToggle}}
        checked={{@isSelected}}
        type="checkbox"
        id="bulk-select-{{@topic.id}}"
        class="bulk-select"
      />
    </label>
  </td>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectCell;
