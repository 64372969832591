import { template as template_c46ee678df3a4a96bda50e6bfe47c8be } from "@ember/template-compiler";
const SidebarSectionMessage = template_c46ee678df3a4a96bda50e6bfe47c8be(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
