import { template as template_288def4d450f49a985b0791b42814ca2 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_288def4d450f49a985b0791b42814ca2(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
